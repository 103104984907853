import React from 'react';

import {
  BooleanInput,
  CardActions,
  CheckboxGroupInput,
  ChipField,
  Create,
  Datagrid,
  DisabledInput,
  Edit,
  EditButton,
  Filter,
  FilterButton,
  FormTab,
  FunctionField,
  ImageField,
  ImageInput,
  LongTextInput,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  RefreshButton,
  required,
  SaveButton,
  SelectInput,
  SingleFieldList,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  ArrayInput,
  SimpleFormIterator,
  RadioButtonGroupInput,
  translate,
} from 'react-admin';
import List from '../customs/List';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Fastfood';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';

export const frontProductsIcon = Icon;
const HideOnCities = ({ classes, record }) => (
  <ArrayInput
    source='hide_on_front'
    label='Hide From Website On Cities:'
    options={{ fullWidth: false }}
  >
    <SimpleFormIterator disableRemove disableAdd options={{ fullWidth: false }}>
      <SelectInput
        source='city_id'
        label='City'
        disabled
        choices={record.cities}
      ></SelectInput>
      <RadioButtonGroupInput
        source='hide'
        label='Choose option'
        options={{ row: true }}
        defaultValue={1}
        fullWidth={true}
        choices={[
          { id: '2', name: 'Same As Mobile' },
          { id: '1', name: 'Hide' },
          { id: '0', name: 'show' },
        ]}
      />
    </SimpleFormIterator>
  </ArrayInput>
);
function booleanCheck(val) {
  if (val) {
    return <Check />;
  } else {
    return <Close />;
  }
}

function sizeCheck(val) {
  if (val.parent_item_id === 0) {
    return 'Main';
  } else {
    if (val.SizeID == 3) {
      return 'XLarge';
    }
    if (val.SizeID == 2) {
      return 'Large';
    }
    // console.log(val);
  }
  // if (val) {
  //   return <Check />;
  // } else {
  //   return <Close />;
  // }
}

const QuickFilter = translate(({ label, translate }) => (
  <Chip>{translate(label)}</Chip>
));

export const FrontProductsFilter = (props) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='q' alwaysOn />
    <SelectInput
      source='gift'
      label='Is gift?'
      choices={[{ id: 1, name: 'Yes' }, { id: 0, name: 'No' }]}
      alwaysOn
    />
    <ReferenceInput
      source='category_id'
      reference='productCategory'
      sort={{ field: 'id', order: 'ASC' }}
    >
      <SelectInput source='name' />
    </ReferenceInput>
    <SelectInput
      source='city_id'
      defaultValue={0}
      choices={[
        { id: 0, name: 'All' },
        { id: 1, name: 'Tanta' },
        { id: 5, name: 'Mahallah' },
        { id: 2, name: 'Zagazig' },
        { id: 6, name: 'Cairo' },
      ]}
    />
  </Filter>
);

const ActionsRefresh = (props) => (
  <CardActions>
    <RefreshButton />
    <FilterButton source={FrontProductsFilter} />
  </CardActions>
);

const listStyles = {
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  image: {
    width: '200px',
  },
  CardActions: {
    disblay: 'none',
  },
};

export const frontProductsList = withStyles(listStyles)(
  ({ classes, ...props }) => {
    // let productId = new URLSearchParams(props.location.search).get(
    //   'parent_item_id',
    // );
    // let customeFilters = { only_parent: 1 };
    // if (productId) {
    //   customeFilters.parent_item_id = 1;
    // }
    return (
      <List
        perPage={25}
        title='Website Prodcuts List'
        {...props}
        filters={<FrontProductsFilter />}
        bulkActionButtons={false}
        // filterDefaultValues={customeFilters}
      >
        <Datagrid>
          <TextField source='name_front' />
          <ImageField
            source='photo'
            classes={{
              image: classes.image,
            }}
            title='picture.title'
          />
          <ReferenceArrayField
            label='Featured In'
            reference='cities'
            source='featured_on_cities'
          >
            <SingleFieldList>
              <ChipField source='name' />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField
            label='Hidden In'
            reference='cities'
            source='hide_on_cities'
          >
            <SingleFieldList>
              <ChipField source='name' />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceField
            label='Category'
            source='product_category_id'
            reference='productCategory'
            linkType={localStorage.getItem('editorAdmin') != 1 ? 'show' : false}
          >
            <TextField source='name' />
          </ReferenceField>
          {/* <FunctionField label='Size' render={(record) => sizeCheck(record)} /> */}
          <EditButton />
          <TextField source='city_name' />
        </Datagrid>
      </List>
    );
  },
);

const createStyles = {
  stock: { width: '5em' },
  fullWidth: { width: '100%' },
  price: { width: '5em' },
  width: { width: '5em' },
  label: { color: 'red' },
  widthFormGroup: { display: 'inline-block' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

export const frontProductsCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props} title='Create porduct' classes={classes}>
      <TabbedForm redirect='list'>
        <FormTab label='English Content'>
          <TextInput
            source='name'
            className={classes.fullWidth}
            label='Product name'
            validate={required()}
          />
          <LongTextInput source='description' label='Description' />
          <ImageInput
            source='image'
            label='Product photo for website'
            accept='image/*'
            validate={required()}
          >
            <ImageField source='image' title='title' />
          </ImageInput>
          <ImageInput
            source='image_mobile'
            label='Product photo for mobile'
            accept='image/*'
            validate={required()}
          >
            <ImageField source='image_mobile' title='title' />
          </ImageInput>
        </FormTab>
        <FormTab label='Arabic Content'>
          <TextInput
            source='name_ar'
            className={classes.fullWidth}
            label='Product name in arabic'
            validate={required()}
          />
          <LongTextInput
            source='description_ar'
            label='Description in arabic'
          />
          <ImageInput
            source='image_ar'
            label='Product photo for website in Arabic'
            accept='image/*'
            validate={required()}
          >
            <ImageField source='image_ar' title='title' />
          </ImageInput>
          <ImageInput
            source='image_mobile_ar'
            label='Product photo for mobile in Arabic'
            accept='image/*'
            validate={required()}
          >
            <ImageField source='image_mobile_ar' title='title' />
          </ImageInput>
        </FormTab>
        <FormTab label='Product properties'>
          <ReferenceInput
            source='product_category_id'
            label='Product category'
            reference='productCategory'
            validate={required()}
          >
            <SelectInput optionText='name' optionValue='id' />
          </ReferenceInput>
          <NumberInput source='small_size' label='Small size price' />
          <NumberInput
            source='medium_size'
            label='Medium size price'
            validate={required()}
          />
          <NumberInput source='large_size' label='Large size price' />
          <BooleanInput label='In stock' source='in_stock' />
          <BooleanInput label='Featured' source='featured' />
        </FormTab>
      </TabbedForm>
    </Create>
  ),
);

const frontProductsTitle = translate(({ record, translate }) => (
  <span>Product ({record.name})</span>
));

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

function pricesCheck(val) {
  return val.productPrices.map((item) => (
    <div key={item.id}>
      <strong style={{ textTransform: 'capitalize' }}>{item.size}</strong> ={' '}
      {item.price}
    </div>
  ));
}

function giftCheck(val) {
  return val.productPrices.map((item) => (
    <BooleanInput label={item.size} source={item.size} />
  ));
}

export const frontProductsEdit = withStyles(createStyles)(
  ({ classes, ...props }) => {
    let setDisabled = localStorage.getItem('editorAdmin') != 1 ? false : true;
    return (
      <Edit {...props} title={<frontProductsTitle />}>
        {/* <TabbedForm redirect="list" asyncValidate={asyncValidate} asyncBlurFields={[ 'image' ]}> */}
        <TabbedForm redirect='list' toolbar={<CustomToolbar />}>
          <FormTab label='English Content'>
            <TextInput
              source='name_front'
              className={classes.fullWidth}
              label='Product name'
              // validate={required()}
            />
            <TextInput
              className={classes.fullWidth}
              source='description_front'
              label='Description'
            />
          </FormTab>
          <FormTab label='Arabic Content'>
            <TextInput
              source='name_front_ar'
              className={classes.fullWidth}
              label='Product name in arabic'
              // validate={required()}
            />
            <LongTextInput
              source='description_front_ar'
              className={classes.fullWidth}
              label='Description in arabic'
            />
          </FormTab>
          <FormTab label='Product properties'>
            <FunctionField
              label='Product Prices'
              render={(record) => pricesCheck(record)}
            />
            <ReferenceArrayInput
              label='(Website) New in cities :'
              reference='cities'
              disabled={setDisabled}
              filter={{ product_id: props.id }}
              style={{ width: '100%' }}
              source='new_on_cities'
            >
              <CheckboxGroupInput>
                <ChipField source='new_on_cities' />
              </CheckboxGroupInput>
            </ReferenceArrayInput>

            <ReferenceArrayInput
              label='(Website) Cold in cities :'
              reference='cities'
              disabled={setDisabled}
              filter={{ product_id: props.id }}
              style={{ width: '100%' }}
              source='cold_on_cities'
            >
              <CheckboxGroupInput>
                <ChipField source='cold_on_cities' />
              </CheckboxGroupInput>
            </ReferenceArrayInput>

            <ReferenceArrayInput
              label='(Website) Hot in cities :'
              reference='cities'
              disabled={setDisabled}
              filter={{ product_id: props.id }}
              style={{ width: '100%' }}
              source='hot_on_cities'
            >
              <CheckboxGroupInput>
                <ChipField source='hot_on_cities' />
              </CheckboxGroupInput>
            </ReferenceArrayInput>
            <ReferenceArrayInput
              label='Hide from Mobile App in cities :'
              reference='cities'
              disabled={setDisabled}
              filter={{ product_id: props.id }}
              style={{ width: '100%' }}
              source='hide_on_cities'
            >
              <CheckboxGroupInput>
                <ChipField source='hide_on_cities' />
              </CheckboxGroupInput>
            </ReferenceArrayInput>
            <HideOnCities />
          </FormTab>
        </TabbedForm>
      </Edit>
    );
  },
);
