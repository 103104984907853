import React from 'react';
import {
    ArrayInput,
    CheckboxGroupInput, ChipField,
    Create,
    Datagrid,
    Edit,
    EditButton,
    Filter,
    FormTab,
    ImageField,
    LongTextInput,
    NumberInput, ReferenceArrayInput,
    ReferenceInput,
    required,
    SaveButton,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar,
    translate,
} from 'react-admin';
import List from '../customs/List';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/AddToPhotos';

export const frontProductsAdditionCategoryIcon = Icon;

const QuickFilter = translate(({label, translate}) => (
    <Chip>{translate(label)}</Chip>
));
const CustomPrices = ({classes, record}) => (
    <ArrayInput source='prices' options={{fullWidth: false}}>
        <SimpleFormIterator disableRemove disableAdd options={{fullWidth: false}}>
            <SelectInput
                source='city_id'
                label='City'
                disabled
                choices={record.cities}
            ></SelectInput>
            <TextInput source='price'/>
        </SimpleFormIterator>
    </ArrayInput>
);
export const frontProductsAdditionCategoryFilter = (props) => (
    <Filter {...props}>
        <TextInput label='pos.search' source='q' alwaysOn/>
        <ReferenceInput
            source='category_id'
            reference='categories'
            sort={{field: 'id', order: 'ASC'}}
        >
            <SelectArrayInput source='name'/>
        </ReferenceInput>
        <NumberInput source='width_gte'/>
        <NumberInput source='width_lte'/>
        <NumberInput source='height_gte'/>
        <NumberInput source='height_lte'/>
        <QuickFilter
            label='resources.ProductsAdditionCategory.fields.stock_lte'
            source='stock_lte'
            defaultValue={10}
        />
    </Filter>
);

export const frontProductsAdditionCategoryList = (props) => (
    <List perPage={25} {...props}>
        <Datagrid>
            <TextField source='name_front'/>
            <ImageField source='photo' title='picture.title'/>

            {/* <ReferenceManyField
        label="For products"
        source="product_id"
        target="product_idss"
        reference="Products"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField> */}
            <EditButton/>
            {/* <DeleteButton /> */}
        </Datagrid>
    </List>
);

const createStyles = {
    stock: {width: '5em'},
    price: {width: '5em'},
    width: {width: '5em'},
    widthFormGroup: {display: 'inline-block'},
    height: {width: '5em'},
    heightFormGroup: {display: 'inline-block', marginLeft: 32},
};

const handleChangeImage = (evt) => {
};

export const frontProductsAdditionCategoryCreate = withStyles(createStyles)(
    ({classes, ...props}) => (
        <Create {...props} title='Create porduct addition'>
            <TabbedForm redirect='list'>
                <FormTab label='English Content'>
                    <TextInput
                        source='name_front'
                        className={classes.fullWidth}
                        label='Addition name'
                        validate={required()}
                    />
                    <LongTextInput source='description_front' label='Description'/>
                </FormTab>
                <FormTab label='Arabic Content'>
                    <TextInput
                        source='name_front_ar'
                        className={classes.fullWidth}
                        label='Addition name'
                        validate={required()}
                    />
                    <LongTextInput source='description_front_ar' label='Description'/>
                </FormTab>
            </TabbedForm>
        </Create>
    ),
);

const frontProductsAdditionCategoryTitle = translate(({record, translate}) => (
    <span>
    {translate('ProductsAdditionCategory.name')} ({record.name})
  </span>
));

const editStyles = {
    ...createStyles,
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
    <Toolbar {...props}>
        <SaveButton/>
    </Toolbar>
));

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export const frontProductsAdditionCategoryEdit = withStyles(editStyles)(
    ({classes, ...props}) => (
        <Edit {...props} title={<frontProductsAdditionCategoryTitle/>}>
            <SimpleForm redirect='list' toolbar={<CustomToolbar/>}>
                <TextInput
                    source='name_front'
                    className={classes.fullWidth}
                    label='Addition name (English)'
                    validate={required()}
                />
                <TextInput
                    source='name_front_ar'
                    className={classes.fullWidth}
                    label='Addition name (Arabic)'
                    validate={required()}
                />
                <ReferenceArrayInput
                    label='Hide from Mobile App in cities :'
                    reference='cities'
                    style={{ width: '100%' }}
                    source='hide_on_front'
                >
                    <CheckboxGroupInput>
                        <ChipField source='hide_on_front' />
                    </CheckboxGroupInput>
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    ),
);
