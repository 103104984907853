import React from 'react';
import {
  translate,
  Create,
  Datagrid,
  Edit,
  ArrayField,
  FunctionField,
  DeleteButton,
  AutocompleteInput,
  Filter,
  SimpleForm,
  NumberInput,
  ImageInput,
  ImageField,
  ReferenceInput,
  Show,
  SimpleShowLayout,
  ShowButton,
  DisabledInput,
  SelectInput,
  TextField,
  ReferenceField,
  TextInput,
  BooleanInput,
  required,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import List from '../customs/List';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Shop';
import Poster from './Poster';
import ApproveButton from './ApproveButton';
import SendPOSButton from './SendPOSButton';
import CancelButton from './CancelButton';
import RejectButton from './RejectButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const OrdersIcon = Icon;

const QuickFilter = translate(({ label, translate }) => (
  <Chip>{translate(label)}</Chip>
));

export const OrdersFilter = (props) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='q' alwaysOn />
    <ReferenceInput
      source='user_id'
      reference='users'
      sort={{ field: 'id', order: 'ASC' }}
    >
      <AutocompleteInput source='name' enableSearch={true} />
    </ReferenceInput>
    <DateInput source='ordered_on' label='Ordered on' />
    <DateInput source='from_date' label='From date' />
    <DateInput source='to_date' label='To date' />
    <ReferenceInput
      source='city_id'
      reference='cities'
      sort={{ field: 'id', order: 'ASC' }}
    >
      <SelectInput source='name' />
    </ReferenceInput>

    {/* <QuickFilter
      label="resources.Orders.fields.stock_lte"
      source="stock_lte"
      defaultValue={10}
    /> */}
  </Filter>
);

const RowStyle = (record, index) => ({
  backgroundColor:
    record.order_status === 'Canceled'
      ? 'rgb(255 0 0 / 9%)'
      : record.order_status === 'pending_cancel'
      ? '#e4ce38'
      : record.order_status === 'Completed'
      ? '#0080003b'
      : 'white',
});

export const OrdersList = (props) => (
  <List
    perPage={25}
    title='Orders'
    {...props}
    bulkActionButtons={false}
    filters={<OrdersFilter />}
  >
    <Datagrid rowStyle={RowStyle}>
      <TextField source='daily_id' label='ID' />
      <TextField
        source='user_order_no'
        label='User Completed Orders'
        style={{
          width: '100px',
          color: 'green',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      />
      <ReferenceField
        label='User'
        source='user_id'
        reference='users'
        linkType={localStorage.getItem('editorAdmin') != 1 ? 'show' : false}
      >
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField
        label='Mobile'
        source='user_id'
        reference='users'
        linkType={localStorage.getItem('editorAdmin') != 1 ? 'show' : false}
      >
        <TextField source='mobile_number' />
      </ReferenceField>
      <ArrayField source='serviceRate' label='Order rate'>
        <Datagrid>
          <TextField source='waffle' />
          <TextField source='crepe' />
          <TextField source='delivery' />
        </Datagrid>
      </ArrayField>

      <TextField source='platform' label='Platform' />
      <TextField source='payment_method' />
      <TextField source='payment_status' />
      <TextField source='grand_totlal' label='Grand Total' />
      <TextField source='order_status' />
      <TextField source='created_at' />
      <TextField source='city_name' label='City' />
      <ShowButton />
      {localStorage.getItem('editorAdmin') != 1 && <ApproveButton />}
      {localStorage.getItem('editorAdmin') != 1 && <RejectButton />}
      {/* <FunctionField
        label='Progress'
        render={function render(record) {
          if (
            record.payment_method == 'visa' &&
            record.order_status != 'canceled' &&
            record.payment_status != 'SUCCESS'
          )
            return (
              <>
                <SendPOSButton record={record} />
                <CancelButton record={record} />
              </>
            );
        }}
      /> */}
    </Datagrid>
  </List>
);

const createStyles = {
  stock: { width: '5em' },
  price: { width: '5em' },
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const handleChangeImage = (evt) => {};

export const OrdersCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props} title='Create porduct'>
      <SimpleForm redirect='list'>
        <TextInput source='name' label='Product name' />
        <ReferenceInput
          source='product_category_id'
          label='Product category'
          reference='productCategory'
        >
          <SelectInput optionText='name' optionValue='id' />
        </ReferenceInput>
        <NumberInput source='price' />
        <BooleanInput label='In stock' source='in_stock' />
        <BooleanInput label='Featured' source='featured' />
        <ImageInput source='image' label='Related pictures' accept='image/*'>
          <ImageField source='image' title='title' />
        </ImageInput>
      </SimpleForm>
    </Create>
  ),
);

const OrdersTitle = translate(({ record, translate }) => (
  <span>
    {translate('Orders.name')} #{record.id}
  </span>
));

const editStyles = {
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  input2: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '& p': {},
  },
  input2: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  bold: {
    fontWeight: 'bold',
  },
};

export const OrdersEdit = withStyles(editStyles)(({ classes, ...props }) => (
  <Edit {...props} title={<OrdersTitle />}>
    <SimpleForm>
      <Poster />
      <ImageInput
        source='image'
        onChange={handleChangeImage}
        label='Related pictures'
        accept='image/*'
      >
        <ImageField source='image' onChange={handleChangeImage} title='title' />
      </ImageInput>
      <DisabledInput label='Id' source='id' />
      <TextInput source='name' validate={required()} />

      <NumberInput source='price' />
      <BooleanInput label='In stock' source='in_stock' />
      <BooleanInput label='Featured' source='featured' />
      <ReferenceInput
        source='product_category_id'
        label='Product category'
        reference='productCategory'
      >
        <SelectInput optionText='name' optionValue='id' />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
));

export const OrderShow = withStyles(editStyles)(({ classes, ...props }) => (
  <Show {...props} actions={false}>
    <SimpleShowLayout>
      <TextField
        className={classes.input2}
        style={{ display: 'inline' }}
        source='id'
      />
      <TextField className={classes.input2} source='promo_code' />
      <TextField
        className={classes.input2}
        source='promo_voucher'
        label='Voucher used?'
      />
      <TextField className={classes.input2} source='general_discount' />
      <TextField
        className={classes.input2}
        source='total_price'
        title='Total before discount'
      />
      <TextField
        className={classes.input2}
        source='total_after_discount'
        title='Total before discount (if exists)'
      />
      <TextField
        className={classes.input2}
        source='fees'
        title='Delivery fees'
      />
      <TextField
        className={classes.input2}
        source='grand_totlal'
        label='Grand total'
      />
      <ReferenceField
        label='User'
        source='user_id'
        reference='users'
        linkType={localStorage.getItem('editorAdmin') != 1 ? 'show' : false}
      >
        <TextField source='name' />
      </ReferenceField>
      <ArrayField source='selected_address' label='User Address'>
        <Datagrid>
          <TextField source='area_name' label='Area' />
          <TextField source='street' />
          <TextField source='building' />
          <TextField source='floor' />
          <TextField source='description' />
        </Datagrid>
      </ArrayField>
      <TextField className={classes.bold} source='order_status' />
      <TextField className={classes.bold} source='reject_cause' />
      <TextField className={classes.bold} source='notes' />
      <ArrayField source='order_detailes' label='Products'>
        <Datagrid>
          <ReferenceField
            label='Product name'
            source='product_id'
            reference='products'
            linkType='show'
          >
            <TextField source='name' />
          </ReferenceField>
          <TextField source='quantity' />
          <TextField source='price' />
          <TextField source='notes' />
          <ArrayField source='order_products_addition' label='Adds'>
            <Datagrid>
              <ReferenceField
                label='Add name'
                source='product_addition_id'
                reference='productsAddition'
                linkType='show'
              >
                <TextField source='name' />
              </ReferenceField>
              <TextField source='price' />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </ArrayField>
      {/* <ReferenceManyField
        label="Comments by"
        reference="products"
        source="order_detailes"
        target="id"
      >
          <ChipField source="name" />
          <Datagrid >
                    <TextField source="name" />
                    <ReferenceField
                      label="User"
                      source="product_id"
                      reference="products"
                      linkType="show"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    
                    
                </Datagrid>
      </ReferenceManyField> */}
      {/* <TextField className={classes.input} source="landline_number" required />
        <TextField className={classes.input} source="mobile_number" required />
        <TextField className={classes.input} source="address" required />
        <TextField className={classes.input} label="Password" source="password" type="password" />
        <Poster />
        <BooleanField source="is_admin" />
        <BooleanField source="confirmed" /> */}
    </SimpleShowLayout>
  </Show>
));
