import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import {ImageField, ImageInput} from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

const styles = {
    root: {display: 'inline-block', marginTop: '1em', zIndex: 2},
    content: {padding: 0, '&:last-child': {padding: 0}},
    img: {
        width: 'initial',
        minWidth: 'initial',
        maxWidth: '42em',
        maxHeight: '15em',
    },
};

function deletePhoto(e, id, photo, field_name) {
    e.preventDefault();
    sendDelPhoto(id, photo, field_name);
    document.getElementById('image_photo').remove();
}

function sendDelPhoto(id, photo, field_name) {
    const request = new Request(
        process.env.REACT_APP_CLIENT_URL +
        '/api/delProductPhoto/' +
        id +
        '?type=' +
        photo +
        '&field_name=' +
        field_name,

        {
            method: 'POST',
            //   body: JSON.stringify({values}),
            headers: new Headers({
                'Content-Type': 'application/json',
                'X-Authorization-Token': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Client-Type': 'web',
                'Access-Control-Allow-Origin': '*',
            }),
        },
    );
    return fetch(request);
}

const Poster = withStyles(styles)(({classes, record, ...props}) => {
    let images_name = props.source ? props.source : 'image';
    const images_list = record[images_name];
    return (
        <Card className={classes.root}>
            {!props.setDisabled && (
                <ImageInput
                    source={props.source ? props.source : 'image'}
                    label={props.title ? props.title : 'Ad photo'}
                    accept='image/*'
                    multiple={props.multiple}
                    placeholder={props.placeholder ? props.placeholder : "If you want to change the photo drop your file here or click."}
                >
                    <ImageField source={props.source ? props.source : 'image'} title='title'/>
                </ImageInput>
            )}
            <>
                {images_list && (Array.isArray(images_list) ? images_list.map((item) =>
                            <CardContent className={classes.content} id='image_photo'>
                                <img src={item.src} alt='' className={classes.img}/>
                                {!props.setDisabled && item && (
                                    <Button onClick={(e) => deletePhoto(e, item.id, 'photo', item.field_name)}>
                                        <DeleteIcon style={{color: 'red'}}/>
                                    </Button>
                                )}
                            </CardContent>
                        ) :
                        <CardContent className={classes.content} id='image_photo'>
                            <img src={images_list.src} alt='' className={classes.img}/>
                            {!props.setDisabled && images_list && (
                                <Button onClick={(e) => deletePhoto(e, images_list.id, 'photo', images_list.field_name)}>
                                    <DeleteIcon style={{color: 'red'}}/>
                                </Button>
                            )}
                        </CardContent>
                )}
            </>
        </Card>
    );
});

export default Poster;
