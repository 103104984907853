import 'babel-polyfill';
import React, {Component} from 'react';
import {Admin, Resource} from 'react-admin';
import './App.css';
import authProvider from './authProvider';
import sagas from './sagas';
import themeReducer from './themeReducer';
import Login from './Login';
import Layout from './Layout';
import Menu from './Menu';
import Dashboard from './dashboard/Dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';
import MyLayout from './MyLayout';

import {HomeSliderCreate, HomeSliderEdit, HomeSliderIcon, HomeSliderList,} from './homeSlider';
import {GalleryCreate, GalleryEdit, GalleryIcon, GalleryList,} from './gallery';
import {GiftSettingEdit, GiftSettingIcon,} from './giftSetting';
import {ProductsEdit, ProductsIcon, ProductsList} from './products';
import {OrdersEdit, OrderShow, OrdersIcon, OrdersList,} from './orders';
import {PendingOrdersEdit, PendingOrdersIcon, PendingOrdersList, PendingOrdersShow,} from './pending_orders';
import {TransactionsIcon, TransactionsList} from './transactions';
import {ProductsAdditionEdit, ProductsAdditionIcon, ProductsAdditionList,} from './productsAddition';
import {ProductsAdditionCategoryEdit, ProductsAdditionCategoryList,} from './productsAdditionCategory';
import {ProductCategoryEdit, ProductCategoryIcon, ProductCategoryList,} from './productCategory';
import {UsersCreate, UsersEdit, UserShow, UsersIcon, UsersList,} from './users';
import {UserPointsIcon, UserPointsList} from './UserPoints';
import {RedeemsIcon, RedeemsList} from './UserRedeems';
import {AreasEdit, AreasIcon, AreasList} from './areas';
import {BranchesCreate, BranchesEdit, BranchesIcon, BranchesList,} from './branches';
import {CitiesEdit, CitiesIcon, CitiesList} from './cities';
import {NotificationsIcon, NotificationsList} from './notifications';
import {PromoCreate, PromoEdit, PromoIcon, PromoList} from './promoCodes';
import {ContactUsIcon, ContactUsList, ContactUsShow} from './contactUs';
import createHistory from 'history/createBrowserHistory';
import dataProviderFactory from './dataProvider';
import {frontProductCategoryEdit, frontProductCategoryList,} from './frontProductCategory';
import {frontProductsEdit, frontProductsList} from './frontProducts';
import {frontProductsAdditionCategoryEdit, frontProductsAdditionCategoryList,} from './frontProductsAdditionCategory';

const messages = {
    en: englishMessages,
};
const i18nProvider = (locale) => messages[locale];

class App extends Component {
    state = {dataProvider: null};

    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
        };
    }

    async componentWillMount() {
        this.checkAuth(this);
        this.checkAuth2(this);
        const dataProvider = await dataProviderFactory(
            process.env.REACT_APP_DATA_PROVIDER,
        );

        this.setState({dataProvider});
    }

    componentWillUnmount() {
        this.restoreFetch();
    }

    checkAuth(that) {
        const request = new Request(
            process.env.REACT_APP_CLIENT_URL + '/api/checkToken',
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'X-Authorization-Token': '12b20fa6cca0ee113dc92d16f6be3029m',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Access-Control-Allow-Origin': '*',
                }),
            },
        );
        return fetch(request)
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                if (
                    localStorage.getItem('token') &&
                    myJson.error &&
                    myJson.error === 'Unauthenticated.'
                ) {
                    localStorage.clear();

                    const h = createHistory();
                    h.push('/#login', {some: 'state'});

                    that.forceUpdate();
                }
            });
    }

    checkAuth2(that) {
        const request2 = new Request(
            process.env.REACT_APP_CLIENT_URL + '/api/products',
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'X-Authorization-Token': '12b20fa6cca0ee113dc92d16f6be3029m',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Access-Control-Allow-Origin': '*',
                }),
            },
        );
        return fetch(request2)
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                const myObjStr = JSON.stringify(myJson);
                localStorage.setItem('products', myObjStr);
            });
    }

    render() {
        const {dataProvider} = this.state;

        if (!dataProvider) {
            return (
                <div className='loader-container'>
                    <div className='loader'>Loading...</div>
                </div>
            );
        }
        if (
            localStorage.getItem('subAdmin') !== '1' &&
            localStorage.getItem('editorAdmin') !== 1
        ) {
            return (
                <Admin
                    title='Crepe & Waffle Admin Panel'
                    dataProvider={dataProvider}
                    customReducers={{theme: themeReducer}}
                    customSagas={sagas}
                    customRoutes={customRoutes}
                    authProvider={authProvider}
                    dashboard={Dashboard}
                    loginPage={Login}
                    // appLayout={Layout}
                    appLayout={MyLayout}
                    menu={Menu}
                    locale='en'
                    i18nProvider={i18nProvider}
                >
                    <Resource
                        name='transactions'
                        list={TransactionsList}
                        icon={TransactionsIcon}
                    />
                    <Resource
                        name='homeSlider'
                        list={HomeSliderList}
                        create={HomeSliderCreate}
                        edit={HomeSliderEdit}
                        icon={HomeSliderIcon}
                    />
                    <Resource
                        name='gallery'
                        list={GalleryList}
                        create={GalleryCreate}
                        edit={GalleryEdit}
                        icon={GalleryIcon}
                    />
                    <Resource
                        name='giftSetting'
                        list={GiftSettingEdit}
                        edit={GiftSettingEdit}
                        icon={GiftSettingIcon}
                    />
                    <Resource
                        name='products'
                        list={ProductsList}
                        edit={ProductsEdit}
                        icon={ProductsIcon}
                    />
                    <Resource
                        name='productsAdditionCategory'
                        edit={ProductsAdditionCategoryEdit}
                        list={ProductsAdditionCategoryList}
                    />
                    <Resource
                        name='productsAddition'
                        list={ProductsAdditionList}
                        edit={ProductsAdditionEdit}
                        icon={ProductsAdditionIcon}
                    />
                    <Resource
                        name='productCategory'
                        list={ProductCategoryList}
                        edit={ProductCategoryEdit}
                        icon={ProductCategoryIcon}
                    />
                    <Resource
                        name='users'
                        list={UsersList}
                        create={UsersCreate}
                        edit={UsersEdit}
                        show={UserShow}
                        icon={UsersIcon}
                    />
                    <Resource
                        name='usersPoints'
                        list={UserPointsList}
                        icon={UserPointsIcon}
                    />
                    <Resource name='usersRedeems' list={RedeemsList} icon={RedeemsIcon}/>
                    <Resource
                        name='branches'
                        list={BranchesList}
                        create={BranchesCreate}
                        edit={BranchesEdit}
                        icon={BranchesIcon}
                    />
                    <Resource
                        name='areas'
                        list={AreasList}
                        // create={AreasCreate}
                        edit={AreasEdit}
                        icon={AreasIcon}
                    />
                    <Resource
                        name='cities'
                        edit={CitiesEdit}
                        list={CitiesList}
                        icon={CitiesIcon}
                    />
                    <Resource
                        name='promoCodes'
                        list={PromoList}
                        create={PromoCreate}
                        edit={PromoEdit}
                        icon={PromoIcon}
                    />
                    <Resource
                        name='contactUs'
                        list={ContactUsList}
                        icon={ContactUsIcon}
                        show={ContactUsShow}
                    />
                    <Resource
                        name='orders'
                        list={OrdersList}
                        edit={OrdersEdit}
                        show={OrderShow}
                        icon={OrdersIcon}
                    />
                    <Resource
                        name='pendingOrder'
                        list={PendingOrdersList}
                        edit={PendingOrdersEdit}
                        show={PendingOrdersShow}
                        icon={PendingOrdersIcon}
                    />

                    <Resource
                        name='transactions'
                        list={TransactionsList}
                        icon={TransactionsIcon}
                    />
                    <Resource
                        name='notifications'
                        list={NotificationsList}
                        icon={NotificationsIcon}
                    />
                    <Resource
                        name='frontProducts'
                        list={frontProductsList}
                        edit={frontProductsEdit}
                        icon={ProductsIcon}
                    />
                    <Resource
                        name='frontProductCategory'
                        list={frontProductCategoryList}
                        edit={frontProductCategoryEdit}
                        icon={ProductCategoryIcon}
                    />
                    <Resource
                        name='frontProductsAdditionCategory'
                        list={frontProductsAdditionCategoryList}
                        edit={frontProductsAdditionCategoryEdit}
                    />
                </Admin>
            );
        } else if (localStorage.getItem('editorAdmin') === 1) {
            return (
                <Admin
                    title='Crepe & Waffle Admin Panel'
                    dataProvider={dataProvider}
                    customReducers={{theme: themeReducer}}
                    customSagas={sagas}
                    customRoutes={customRoutes}
                    authProvider={authProvider}
                    dashboard={Dashboard}
                    loginPage={Login}
                    appLayout={Layout}
                    menu={Menu}
                    locale='en'
                    i18nProvider={i18nProvider}
                >
                    <Resource
                        name='users'
                        list={UsersList}
                        create={UsersCreate}
                        edit={UsersEdit}
                        show={UserShow}
                        icon={UsersIcon}
                    />
                </Admin>
            );
        } else {
            return (
                <Admin
                    title='Crepe & Waffle Admin Panel'
                    dataProvider={dataProvider}
                    customReducers={{theme: themeReducer}}
                    customSagas={sagas}
                    customRoutes={customRoutes}
                    authProvider={authProvider}
                    dashboard={Dashboard}
                    loginPage={Login}
                    appLayout={Layout}
                    menu={Menu}
                    locale='en'
                    i18nProvider={i18nProvider}
                >
                    <Resource
                        name='homeSlider'
                        list={HomeSliderList}
                        create={HomeSliderCreate}
                        edit={HomeSliderEdit}
                        icon={HomeSliderIcon}
                    />
                    <Resource
                        name='gallery'
                        list={GalleryList}
                        create={GalleryCreate}
                        edit={GalleryEdit}
                        icon={GalleryIcon}
                    />
                    <Resource
                        name='giftSetting'
                        list={GiftSettingEdit}
                        edit={GiftSettingEdit}
                        icon={GiftSettingIcon}
                    />
                    <Resource
                        name='products'
                        list={ProductsList}
                        edit={ProductsEdit}
                        icon={ProductsIcon}
                    />
                    <Resource
                        name='productsAdditionCategory'
                        edit={ProductsAdditionCategoryEdit}
                        list={ProductsAdditionCategoryList}
                    />
                    <Resource
                        name='productsAddition'
                        list={ProductsAdditionList}
                        edit={ProductsAdditionEdit}
                        icon={ProductsAdditionIcon}
                    />
                    <Resource
                        name='productCategory'
                        list={ProductCategoryList}
                        edit={ProductCategoryEdit}
                        icon={ProductCategoryIcon}
                    />
                    <Resource
                        name='users'
                        list={UsersList}
                        show={UserShow}
                        icon={UsersIcon}
                    />
                    <Resource
                        name='usersPoints'
                        list={UserPointsList}
                        icon={UserPointsIcon}
                    />
                    <Resource name='usersRedeems' list={RedeemsList} icon={RedeemsIcon}/>
                    <Resource
                        name='branches'
                        list={BranchesList}
                        create={BranchesCreate}
                        edit={BranchesEdit}
                        icon={BranchesIcon}
                    />
                    <Resource
                        name='areas'
                        list={AreasList}
                        // create={AreasCreate}
                        edit={AreasEdit}
                        icon={AreasIcon}
                    />
                    <Resource name='cities' list={CitiesList} icon={CitiesIcon}/>

                    <Resource
                        name='contactUs'
                        list={ContactUsList}
                        icon={ContactUsIcon}
                        show={ContactUsShow}
                    />
                    <Resource
                        name='orders'
                        list={OrdersList}
                        edit={OrdersEdit}
                        show={OrderShow}
                        icon={OrdersIcon}
                    />
                    <Resource
                        name='frontProducts'
                        list={frontProductsList}
                        edit={frontProductsEdit}
                        icon={ProductsIcon}
                    />
                </Admin>
            );
        }
    }
}

export default App;
