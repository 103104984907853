import React from 'react';
import {
  CheckboxGroupInput,
  ChipField,
  ArrayInput,
  SimpleFormIterator,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FormTab,
  SelectInput,
  ImageField,
  ImageInput,
  RadioButtonGroupInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  SaveButton,
  SingleFieldList,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  translate,
} from 'react-admin';
import Checkbox from 'material-ui/Checkbox';
import List from '../customs/List';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Category';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import Poster from '../posters/Poster';
import PosterAr from '../posters/PosterAr';

export const ProductCategoryIcon = Icon;
const CustomPrices = ({ classes, record }) => (
  <ArrayInput source='hide_on_front' label='Hide From Website On Cities:' options={{ fullWidth: false }}>
    <SimpleFormIterator disableRemove disableAdd options={{ fullWidth: false }}>
      <SelectInput
        source='city_id'
        label='City'
        disabled
        choices={record.cities}
      ></SelectInput>
      <RadioButtonGroupInput
        source='hide'
        label='Choose option'
        options={{ row: true }}
        defaultValue={1}
        fullWidth={true}
        choices={[
          { id: '2', name: 'Same As Mobile' },
          { id: '1', name: 'Hide' },
          { id: '0', name: 'show' },
        ]}
      />
    </SimpleFormIterator>
  </ArrayInput>
);
function booleanCheck(val) {
  if (val) {
    return <Check />;
  } else {
    return <Close />;
  }
}

const renderCheckbox = ({ input, label }) => (
  <Checkbox
    label={label}
    checked={input.value ? true : false}
    onCheck={input.onChange}
  />
);

export const frontProductCategoryList = (props) => (
  <List perPage={25} {...props} title='Front Product Categories List'>
    <Datagrid>
      <TextField source='name_front' />
      <ReferenceArrayField label='Hidden In' reference='cities' source='hide'>
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ReferenceArrayField>
      {/*
      <FunctionField
          label='Hidden'
          render={(record) => booleanCheck(record.hide)}
        /> */}
      <EditButton />
    </Datagrid>
  </List>
);

const createStyles = {
  stock: { width: '5em' },
  price: { width: '5em' },
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

export const frontProductCategoryCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props}>
      <TabbedForm redirect='list'>
        <FormTab label='English Content'>
          <TextInput source='name' />
          <ImageInput source='image' label='The photo' accept='image/*'>
            <ImageField source='image' title='title' />
          </ImageInput>
        </FormTab>
        <FormTab label='Arabic Content'>
          <TextInput source='name_ar' />
          <ImageInput source='image_ar' label='The photo' accept='image/*'>
            <ImageField source='image_ar' title='title' />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Create>
  ),
);

const frontProductCategoryTitle = translate(({ record, translate }) => (
  <span>
    {translate('ProductCategory.name')} #{record.id}
  </span>
));

const editStyles = {
  ...createStyles,
  fullWidth: { width: '100%' },
  bgGreen: { color: '#ff0000' },
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
const RadioChoiceColor = ({ record }) => (
  <span style={{ backgroundColor: record.id, color: 'white', padding: '5px' }}>
    {record.name}
  </span>
);

export const frontProductCategoryEdit = withStyles(editStyles)(
  ({ classes, ...props }) => {
    return (
      <Edit {...props} title={<frontProductCategoryTitle />}>
        <TabbedForm redirect='list' toolbar={<CustomToolbar />}>
          <FormTab label='English Content'>
            <TextInput source='name_front' className={classes.fullWidth} />

            <Poster source='web_image' multiple={true} title={"Product Category Photo for website"} />
          </FormTab>
          <FormTab label='Arabic Content'>
            <TextInput source='name_front_ar' className={classes.fullWidth} />
            <Poster source='web_image_ar' multiple={true} title={"Product Category Photo for website"} />
          </FormTab>
          <FormTab label='Category properties'>
            <RadioButtonGroupInput
              source='color_bg'
              label='Choose category title background color'
              className={classes.fullWidth}
              optionText={<RadioChoiceColor />}
              choices={[
                { id: '#62696F', name: 'Gray' },
                { id: '#8BC341', name: 'Green' },
              ]}
            />
            <ReferenceArrayInput
              label='Hide from Mobile App in cities :'
              reference='cities'
              style={{ width: '100%' }}
              source='hide'
            >
              <CheckboxGroupInput>
                <ChipField source='hide' />
              </CheckboxGroupInput>
            </ReferenceArrayInput>
            <CustomPrices />
          </FormTab>
        </TabbedForm>
      </Edit>
    );
  },
);
